import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../../../common/section";

export const Comparison: React.FC = () => {
  const {t} = useTranslation();

  return (
      <Section>

        <h1>{t('products.hyperion.comparison.heading2')}</h1>

        <table>
          <thead>
          <tr>
            <th>Model</th>
            <th>HO-1000</th>
            <th>HO-1500</th>
            <th>HO-2000</th>
            <th>HPRO-5000</th>
            <th>HPRO-10000</th>
            <th>HPRO-15000</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Air Flow</td>
            <td>1000m/h</td>
            <td>1500m/h</td>
            <td>2000m/h</td>
            <td>5000m/h</td>
            <td>10000m/h</td>
            <td>15000m/h</td>
          </tr>
          <tr>
            <td>Voltage (V)</td>
            <td>230</td>
            <td>230</td>
            <td>230</td>
            <td>230</td>
            <td>230/400</td>
            <td>230/400</td>
          </tr>
          <tr>
            <td>Capacity (W) max</td>
            <td>650</td>
            <td>750</td>
            <td>850</td>
            <td>1500</td>
            <td>2550</td>
            <td>3650</td>
          </tr>
          <tr>
            <td>Uv-c Radiation (W)</td>
            <td>510W</td>
            <td>510W</td>
            <td>510W</td>
            <td>850W</td>
            <td>850W</td>
            <td>850W</td>
          </tr>
          <tr>
            <td>Auto Program</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Hepa Filter</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Carbon Filter</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>internet Unit</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          </tbody>
        </table>

      </Section>
  )
}
